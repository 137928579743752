<template>
  <div id="create-category-course">
    <div class="div-companies bg-white content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <h4 style="color: #0007" class="mt-3">
                <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                Academy - Criar Categoria
              </h4>
              <div>
                <button @click="saveCategory()" class="btn btn-sm btn-primary mr-2">
                  Salvar
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click.prevent="$router.go(-1)">
                  <i class="fa fa-arrow-left mx-1"></i>Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- cadastro de categoria -->
      <div class="row ml-1 mr-1">
        <div class="card w-100 mt-2 ml-2 mr-2 mb-3">
          <!-- nome da categoria -->
          <div class="col-8 col-sm-6 col-md-8 col-lg-6 d-flex flex-column mb-3 mt-2 ml-2">
            <label>
              Nome da Categoria
              <span v-if="(category.name.length < 4 || category.name.length > 25) && nameOk"
                    class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">(o nome deve ter entre 4 e 25 caracteres)</span>
            </label>
            <input v-model="category.name" type="text" class="form-control" autocomplete="off"/>
            <span v-if="category.name.length>20" class="text-small mt-1 w-50"
                  :class="category.name.length>25 ? 'text-danger':'text-dark'" role="alert" style="font-size: .7rem">Tamanho do nome: {{
                category.name.length
              }}/25</span>

          </div>
          <!-- planos da categoria -->
          <!-- <div class="col-8 col-sm-6 col-md-8 col-lg-6 d-flex flex-column mb-3 mt-2 ml-2">
            <label>
              Disponivel para o(s) plano(s)
              <span v-if="(category.plans.length <=0 && plansOk)" class="font-weight-normal ml-1 text-danger"
                    style="font-size: .7rem">(selecione ao menos um plano.)</span>
            </label>
            <div class="d-flex flex-row custom-label">
              <div class="mr-2">
                <input type="checkbox" id="basic" name="basic" value="basic" v-model="category.plans">
                <label class="font-weight-normal ml-1" for="basic">Basic</label>
              </div>
              <div class="mr-2">
                <input type="checkbox" id="essential" name="essential" value="essential" v-model="category.plans">
                <label class="font-weight-normal ml-1" for="essential">Essencial</label>
              </div>
              <div class="mr-2">
                <input type="checkbox" id="advanced" name="advanced" value="advanced" v-model="category.plans">
                <label class="font-weight-normal ml-1" for="advanced">Avançado</label>
              </div>
              <div class="mr-2">
                <input type="checkbox" id="professional" name="professional" value="professional"
                       v-model="category.plans">
                <label class="font-weight-normal ml-1" for="professional">Profissional</label>
              </div>
            </div>
          </div> -->
        </div>

        <div class="card w-100 mt-2 ml-2 mr-2 mb-3">
          <div class="col-12 mb-3 mt-2">
            <label class="ml-2">
              Incluir vídeos
              <span v-if="(category.videos.length <=0 && videosOk)" class="font-weight-normal ml-1 text-danger"
                    style="font-size: .7rem">(cadastre ao menos um vídeo.)</span>
            </label>
            <div class="d-flex flex-row flex-wrap">


              <!-- listagem de videos -->
              <div class="col-12 col-sm-6">
                <div class="d-flex flex-column justify-content-center">
                  <div v-if="category.videos<=0">Nenhum video cadastrado até agora.</div>
                  <div v-for="(video, index) in category.videos" :key="index" class="custom-row">
                    <div class="row mt-1 mb-1">
                      <div class="col-2 d-flex justify-content-center align-items-center">
                        <i @click="changeIndex(index)" class="fas fa-arrow-up ml-4 mr-1"
                           style="font-size: .8rem; cursor: pointer" title="Mudar a ordem dos vídeos."></i>
                        <img :src="`https://img.youtube.com/vi/${getId(video.url)}/0.jpg`" class="img-fluid"
                             alt="thumbnail" :title="video.title">
                      </div>
                      <div class="col-10 d-flex justify-content-between">
                        <div class="d-flex flex-column ml-3">
                          <label class="m-0 p-0">
                            {{ video.title }}
                            <span class="text-muted font-weight-normal; word-break: break-word;" style="font-size: .7rem">{{
                                `${Math.trunc(video.duration / 60)}min${Math.trunc(video.duration % 60)}s`
                              }}</span>
                          </label>
                          <p class="m-0 p-0" style="font-size: .8rem">{{ video.description }}</p>
                        </div>
                        <div class="d-flex align-items-center mr-1">
                          <i @click="removeVideo(index, video)" class="fa fa-trash btn-outline-danger btn-sm"
                             style="cursor: pointer" title="Excluir video."></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="d-block d-sm-none border-bottom w-100 mb-3 mt-4"></div>
              <!-- preview e cadastro -->
              <div class="col-12 col-sm-6 d-flex">
                <div class="col-12 col-lg-10 col-xl-8">
                  <label>Preview</label>
                  <div class="overflow-hidden" title="preview">
                    <youtube
                        :video-id="videoId"
                        :player-vars="playerVars"
                        resize
                        fitParent
                        ref="youtube"
                        @playing="playing">
                    </youtube>
                  </div>
                  <!-- titulo do video -->
                  <div>
                    <label class="m-0">
                      Título do vídeo
                      <span v-if="(video.title.length<4 || video.title.length>60) && titleOk"
                            class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">(o titulo deve ter entre 4 e 60 caracteres)</span>
                    </label>
                    <input v-model="video.title" type="text" class="form-control" placeholder="Digite o nome do vídeo.">
                    <span v-if="video.title.length>50" class="text-small mt-1 w-50"
                          :class="video.title.length>60 ? 'text-danger':'text-dark'" role="alert"
                          style="font-size: .7rem">Tamanho do titulo: {{ video.title.length }}/60</span>
                  </div>
                  <!-- descricao do video -->
                  <div>
                    <label class="m-0 mt-2">
                      Descrição do vídeo
                      <span v-if="(video.description.length<4 || video.description.length>200) && descriptionOk"
                            class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">(a descricao deve ter entre 4 e 200 caracteres)</span>
                    </label>
                    <textarea v-model="video.description" class="form-control"
                              placeholder="Digite a descrição do vídeo"></textarea>
                    <span v-if="video.description.length>180" class="text-small mt-1 w-50"
                          :class="video.description.length>200 ? 'text-danger':'text-dark'" role="alert"
                          style="font-size: .7rem">Tamanho da descrição: {{ video.description.length }}/200</span>
                  </div>
                  <!-- url do video -->
                  <div>
                    <label class="m-0 mt-2">
                      URL
                      <span
                          v-if="!(/^((https:\/\/)?(www.)?(youtube)?(youtu.be)?(.com)?)\/(\S*)$/.test(video.url)) && urlOk"
                          class="font-weight-normal ml-1 text-danger"
                          style="font-size: .7rem">(digite uma url válida)</span>
                    </label>
                    <input v-model="video.url" type="text" class="form-control" placeholder="Digite a URL do vídeo">
                    <span v-if="!video.duration && durationOk" class="font-weight-normal ml-1 text-danger"
                          style="font-size: .7rem">(Não foi possivel gerar a duração. Cole novamente a url, por favor.)</span>

                  </div>
                  <!-- cadastrar ou cancelar -->
                  <div class="d-flex justify-content-center mt-3 w-100">
                    <button @click.prevent="createVideo()" class="btn btn-primary mr-1">Cadastrar</button>
                    <button @click.prevent="clearVideo()" class="btn btn-outline-primary">Cancelar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'create_category_course',
  data() {
    return {
      // para o preview do video
      playerVars: {
        autoplay: 1,
        enablejsapi: 1,
        origin: process.env.VUE_APP_URL_APPLICATION,
      },

      // objeto de categoria
      category: {
        name: '',
        courseId: null,
        createdBy: null,
        videos: [],
        plans: []
      },

      // objeto para auxiliar criação de video
      video: {
        title: '',
        description: '',
        url: '',
        duration: null
      },

      // validacoes
      // categoria
      nameOk: false,
      plansOk: false,
      videosOk: false,

      // video
      titleOk: false,
      descriptionOk: false,
      urlOk: false,
      durationOk: false,
      newUrl: false

    }
  },

  computed: {
    videoId: function () {
      this.newUrl = true;
      return this.getId(this.video.url)
    },
    player: function () {
      return this.$refs.youtube.player
    }
  },

  methods: {
    playing: async function () {
      this.video.duration = await this.player.getDuration()
      if (this.newUrl) {
        this.newUrl = false;
        this.player.pauseVideo()
      }
    },
    getId: function (url) {
      return this.$youtube.getIdFromUrl(url)
    },

    /**
     * metodo para fazer validacao antes de salvar a categoria
     */
    beforeSaveCategory: function () {
      // nome da categoria
      this.nameOk = this.category.name.length < 4 || this.category.name.length > 25 ? true : false
      // planos
      // this.plansOk = this.category.plans.length <= 0 ? true : false
      // videos
      this.videosOk = this.category.videos.length <= 0 ? true : false
      return this.nomeOk || this.plansOk || this.videosOk
    },


    /**
     * Metodo para salvar a categoria
     */
    saveCategory: async function () {
      // validacao
      if (this.beforeSaveCategory()) return
      // salvando variaveis: id e nome de usuario ativo
      this.category.courseId = this.$route.params.id
      this.category.createdBy = this.$store.getters.admin.name
      // salvando
      await this.$tallos_academy_api.post(`/categories/${this.$route.params.id}`, this.category)
          .then(response => {
            this.$toasted.global.defaultSuccess({msg: `A categoria '${response.data.name}' foi cadastrada com sucesso.`})
            this.$router.push(`/admin/academy/overview/${this.$route.params.id}`)
          })
          .catch(error => {
            error.response.data.message.forEach(err => {
              this.$toasted.global.defaultError({msg: err})
            })
          })
    },

    /**
     * metodo para fazer validacao antes de salvar um video
     */
    beforeSaveVideo: function () {
      // titulo
      this.titleOk = this.video.title.length < 4 || this.video.title.length > 60 ? true : false
      // descricao
      this.descriptionOk = this.video.description.length < 4 || this.video.description.length > 200 ? true : false
      // url
      this.urlOk = /^((https:\/\/)?(www.)?(youtube)?(youtu.be)?(.com)?)\/(\S*)$/.test(this.video.url) ? false : true
      // duracao
      this.durationOk = this.video.duration ? false : true
      return this.titleOk || this.descriptionOk || this.urlOk || this.durationOk
    },

    /**
     * Metodo que cria um video no objeto videos
     */
    createVideo: function () {
      if (this.beforeSaveVideo()) return
      this.category.videos.push({...this.video})
      this.clearVideo()
    },

    /**
     * Metodo para resetar a variavel auxiliar video
     */
    clearVideo: function () {
      this.video.url = '';
      this.video.description = '';
      this.video.title = '';
    },

    removeVideo: function (index, video) {
      this.video.title = video.title;
      this.video.description = video.description;
      this.video.url = video.url;
      this.category.videos.splice(index, 1)
    },

    /**
     * Metodo para alternar a posição do video
     */
    changeIndex: function (index) {
      if (index === 0) return
      this.category.videos.splice(index - 1, 0, this.category.videos.splice(index, 1)[0]);
    },

    // funcoes no mounted()
    // gerar script do player
    generatePlayerScript: function () {
      let loadScriptVueYT = document.createElement('script');
      loadScriptVueYT.src = "vue-youtube/dist/vue-youtube.js";
      document.head.appendChild(loadScriptVueYT);
    },

  },

  /**
   * Start Vue lifecycle
   */
  mounted: function () {
    this.generatePlayerScript()
    // console.log(this.$refs.youtube)
  }
}
</script>

<style scoped>

* {
  transition: .4s;
}

.custom-buttom {
  font-size: .8rem;
  padding: .2rem .7rem;
}

.custom-row:hover {
  background-color: #eee;
}

iframe {
  width: 100%;
}

</style>